<template>
  <!-- Champ cliquable sans label pour édition rapide -->
  <b-link
    v-if="computedQuickEdit"
    class="quick-edit-link"
    href="#"
    @click="$emit('edit')"
  >
    <slot>{{ value }}</slot>
  </b-link>
  <!-- Champ avec label -->
  <b-form-group
    v-else-if="label"
    :label="label"
    :label-cols="computedLabelCols['xs']"
    :label-cols-sm="computedLabelCols['sm']"
    :label-cols-md="computedLabelCols['md']"
    :label-cols-lg="computedLabelCols['lg']"
    :label-cols-xl="computedLabelCols['xl']"
  >
    <span class="labeled">
      <slot>{{ value }}</slot>
    </span>
  </b-form-group>
  <!-- Champ sans label -->
  <span v-else>
    <slot>{{ value }}</slot>
  </span>
</template>

<script>
import { computed as inputsComputed } from "../../services/inputs.service";
export default {
  name: "GenericReadonlyComponent",
  props: {
    // valeur à afficher (type sans importance)
    value: undefined,
    // propriété label du form-group
    label: String,
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // true pour autoriser l'édition rapide (false par défaut)
    quickEdit: [Boolean, String],
  },
  computed: {
    ...inputsComputed,
    computedQuickEdit() {
      return this.quickEdit === true || this.quickEdit === "true";
    },
  },
};
</script>
