<template>
  <CustomSelectComponent
    ref="select"
    :label="label"
    :labelCols="labelCols"
    :options="options"
    :rules="rules"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></CustomSelectComponent>
</template>

<script>
import CustomSelectComponent from "./CustomSelectComponent.vue";
export default {
  name: "SelectRecolteComponent",
  components: { CustomSelectComponent },
  props: ["value", "label", "labelCols", "rules"],
  computed: {
    options() {
      let recoltes = this.$store.state.ref.recoltes.map((r) => ({
        value: r.codeRecolte,
        text: r.codeRecolte,
      }));
      return [{ value: null, text: "" }, ...recoltes];
    },
  },
  methods: {
    async validate() {
      await this.$refs.select?.validate();
    },
    focus() {
      this.$refs.select?.focus();
    },
  },
};
</script>
